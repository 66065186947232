import './App.css';
import Album from './home';

function App() {
  return (
    <div className="App">
      <Album></Album>
    </div>
  );
}

export default App;
