import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import LocationOn from '@mui/icons-material/LocationOn';
import axios from 'axios';
//import SearchInput from './components/search/search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import './home.css';

const theme = createTheme({
    palette: {
        mode: 'dark',
        action: {
            disabled: 'white'
        }
    }
});

const getButton = (card) => {
    if (card.ticketInformation === null) {
        return <Button style={{
            "display": "flex",
            "margin": "auto",
            "width": "100%",
            "paddingLeft": "40px"
        }} disabled={true} variant="contained" size="large">Sold Out 😞</Button>
    } else if (card.title.includes('Canceled')) {
        return <Button style={{
            "display": "flex",
            "margin": "auto",
            "width": "100%",
            "paddingLeft": "40px"
        }} disabled={true} variant="contained" size="large">Canceled 😞</Button>
    } else {
        return <Button style={{
            "display": "flex",
            "margin": "auto",
            "width": "100%",
            "paddingLeft": "40px"
        }} variant="contained" size="large" onClick={() => {
            window.open(card?.ticketInformation, "_blank");
        }}>Tickets<LaunchIcon style={{ "marginLeft": "10px" }}></LaunchIcon></Button>
    }
}

const getPrice = (price) => {
    if (price === null || price === '') {
        return <span style={{ "fontSize": "25px" }}>🤷</span>
    } else {
        return <Typography style={{ "fontSize": "1.10rem" }}>
            {price}
        </Typography>
    }
}

const getVenueLocation = (venue) => {
    const slowDown = 'https://goo.gl/maps/yXEduTBqv9Dti3QB6';
    const theWaitingRoom = 'https://goo.gl/maps/AN9ZASi5hsrq3mZU9';
    const theAdmiral = 'https://goo.gl/maps/L3tA9shzB8CpeK3u5';
    let venueLocation;

    switch (venue) {
        case 'Slowdown':
            venueLocation = slowDown;
            break;
        case 'The Waiting Room':
            venueLocation = theWaitingRoom;
            break;
        case 'The Admiral':
            venueLocation = theAdmiral;
            break;
        default:
            console.log('Unknown venue');
    }
    return venueLocation;
}

const Album = () => {
    const [name, setName] = useState('');
    const [band, setBand] = useState([]);
    const [loading, setLoading] = useState(false);
    // the search result
    const [foundBand, setFoundBand] = useState([]);

    const filter = (e) => {
        const keyword = e.target.value;


        if (keyword !== '') {
            setFoundBand(band.filter((band2) => {
                return band2.title.toLowerCase().includes(keyword.toLowerCase());
            }));
        } else {
            setFoundBand(band);
        }

        setName(keyword);
    };

    //const [data, setData] = useState([]);
    //const [searchResults, setSearchResults] = useState([]);

    React.useEffect(() => {
        setLoading(true);
        // TODO
        // http://localhost:4000
        axios.get('/api/getBands').then(response => {
            console.log('response: ', response)
            setBand(response.data);
            setFoundBand(response.data);
            setLoading(false);
        });
    }, [setBand]);

    // const callback = useCallback(searchResults => {
    //     const untouchedData = data;
    //     if (searchResults?.name !== '') {
    //         const results = data.filter(band => {
    //             return band.title.toLowerCase().startsWith(searchResults.name.toLowerCase())
    //         });
    //         setData(results);
    //     } else {
    //         setData(untouchedData);
    //     }
    //     console.log(searchResults);
    //     console.log(data);
    //     //setData(data);
    // }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <main>
                { }
                <Box style={{ "paddingBottom": "0px" }}
                    sx={{
                        bgcolor: 'rgb(26, 32, 39)',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="white"
                            gutterBottom
                        >
                            Music in Omaha
                        </Typography>
                        <Typography variant="h5" align="center" color="white" paragraph>
                            Bands playing in Omaha that don't suck 🎸
                        </Typography>
                        <br></br>
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto' }}
                        >
                            <SearchIcon />
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search Bands"
                                inputProps={{ 'aria-label': 'search bands' }}
                                value={name}
                                onChange={filter}
                            />
                        </Paper>
                    </Container>
                </Box>
                <Container sx={{ py: 8 }} maxWidth="lg">
                    {loading && (
                        <CircularProgress color="inherit" />
                    )}
                    {loading && (
                        <Typography variant="h5" align="center" color="white" paragraph>
                            Loading Bands...
                        </Typography>
                    )}
                    {!loading && foundBand.length === 0 && (
                        <Typography variant="h5" align="center" color="white" paragraph>
                            No bands found 😞
                        </Typography>
                    )}
                    <Grid container alignItems="stretch">
                        <Grid container spacing={4}>
                            {foundBand.map((card, _id) => (
                                <Grid item key={_id} xs={12} sm={6} md={4} lg={3}>
                                    <Card variant="outlined" className={'adjust'}
                                        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                    >
                                        {card.venue !== 'The Admiral' && (
                                            <CardMedia
                                                style={{
                                                    "width": "100%",
                                                    "height": "100%",
                                                    "objectFit": "revert"
                                                }}
                                                sx={{ height: '50%' }}
                                                component="img"
                                                image={card.image}
                                                alt="Band Image"
                                            />
                                        )}
                                        {card.venue === 'The Admiral' && (
                                            <CardMedia
                                                style={{
                                                    "width": "100%",
                                                    "objectFit": "fill"
                                                }}
                                                sx={{ height: '50%' }}
                                                component="img"
                                                image={card.image}
                                                alt="Band Image"
                                            />
                                        )}
                                        <CardContent style={{ "paddingBottom": "0" }} sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {card.title}
                                            </Typography>
                                            <Divider style={{
                                                "marginBottom": "10px",
                                                "marginTop": "10px"
                                            }}></Divider>
                                            <Grid container style={{
                                                "display": "flex",
                                                "alignItems": "center"
                                            }}
                                                direction={"row"}>
                                                <Grid item style={{ "width": "10%" }}><span style={{ "fontSize": "25px" }}>📅</span></Grid>
                                                <Grid item xs style={{ "marginLeft": "-1rem" }}>{card.dayName === '' && (
                                                    <Typography gutterBottom variant="h6" component="h2" style={{
                                                        "marginBottom": "0px",
                                                        "fontSize": "1.10rem"
                                                    }}>
                                                        {card.month} {card.day}
                                                    </Typography>
                                                )}
                                                    {card.dayName !== '' && (
                                                        <Typography gutterBottom variant="h6" component="h2" style={{
                                                            "marginBottom": "0px",
                                                            "fontSize": "1.10rem"
                                                        }}>
                                                            {card.month} {card.day} - {card.dayName}
                                                        </Typography>
                                                    )}</Grid>
                                            </Grid>
                                            <Divider style={{
                                                "marginBottom": "10px",
                                                "marginTop": "10px"
                                            }}></Divider>
                                            <Grid container style={{
                                                "display": "flex",
                                                "alignItems": "center"
                                            }}
                                                direction={"row"}>
                                                <Grid item style={{ "width": "20%", "marginLeft": "-1rem" }}><span style={{ "fontSize": "25px" }}>⏰</span></Grid>
                                                <Grid item xs style={{
                                                    "marginRight": "2em",
                                                    "width": "80%"
                                                }}>     <Typography style={{ "fontSize": "1.10rem" }}>
                                                        {card.time}
                                                    </Typography></Grid>
                                            </Grid>
                                            <Divider style={{
                                                "marginBottom": "10px",
                                                "marginTop": "10px"
                                            }}></Divider>
                                            <Grid container style={{
                                                "display": "flex",
                                                "alignItems": "center"
                                            }}
                                                direction={"row"}>
                                                <Grid item style={{ "width": "20%", "marginLeft": "-1rem" }}><span style={{ "fontSize": "25px" }}>💵</span></Grid>
                                                <Grid item xs style={{
                                                    "marginRight": "2em",
                                                    "width": "80%"
                                                }}>
                                                    {getPrice(card.price)}
                                                    {/* <Typography>
                                                        {card.price}
                                                    </Typography> */}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions style={{ flexDirection: 'column' }}>
                                            <Button style={{
                                                "display": "flex",
                                                "margin": "auto",
                                                "width": "100%",
                                                "marginBottom": "15px"
                                            }} variant="outlined" size="large" onClick={() => {
                                                window.open(getVenueLocation(card.venue), "_blank");
                                            }}>{card.venue}<LocationOn style={{ "marginLeft": "10px" }}></LocationOn></Button>
                                            {getButton(card)}
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <Box style={{
                "flexShrink": "0"
            }} sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                <Typography variant="h6" align="center" gutterBottom>
                    End of the road
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    Made with ❤️ by Scott Hansen
                </Typography>
            </Box>
            {/* End footer */}
        </ThemeProvider >
    );
}

export default Album;